import amplitude from "amplitude-js"

export const AMPLITUDE_API_KEY = "e4d003fcbc12f6a635a5483410f4b8eb";
amplitude.getInstance().init(AMPLITUDE_API_KEY);

const logRoute = (route, additionalInfo) => {
  additionalInfo = additionalInfo || {};
  const isBonusCoin = additionalInfo.isBonusCoin || false;

  amplitude.logEvent("route", {
    route,
    isBonusCoin,
  });
}

export default logRoute;
