import routes, {SPECIAL_CODES_ROUTE} from "./redirect-config";
import amplitudeLogRoute from "./amplitude-log-route";

const redirect = () => {
  window.localStorage?.setItem("needsRefresh", "yes");
  const specialRoute = "/coins/";
  const isSpecialRoute = window.location.pathname.startsWith("/coins/");

  // Log analytics for route
  amplitudeLogRoute(window.location.pathname, {
    isBonusCoin: isSpecialRoute,
  });

  if (isSpecialRoute) {
    const trailingPath = window.location.pathname.substring(specialRoute.length)
    window.location.replace(routes[SPECIAL_CODES_ROUTE](trailingPath));
    return;
  }

  if (Object.keys(routes).indexOf(window.location.pathname) !== -1) {
    window.location.replace(routes[window.location.pathname]);
  } else if (routes.default) {
    if (window.location.pathname !== routes.default) {
      window.location.replace(routes.default);
    }
  } else {
    throw new Error("Redirect routes are not configured correctly");
  }
};

if (window.localStorage?.getItem("needsRefresh")) {
  window.localStorage?.removeItem("needsRefresh");
  window.location.reload();
} else {
  redirect();
}






