export const SPECIAL_CODES_ROUTE = "/coins/*";

const routes = {
  default: "/404",
  "/not-logged-in-but-found-bonus-code": "https://info.ggc.nyc/",
  "/1": "https://ggc.8thwall.app/prism/",
  "/promo": "https://ggc.8thwall.app/prism/",
  "/lincoln": "https://open.spotify.com/playlist/5RicwlC5pYPpCbIB0XBKUi?si=7de0071ec30b40cc",
  "/am": "https://andrewmcmahon.ffm.to/starscoloringchallenge",
  [SPECIAL_CODES_ROUTE]: (code) => `https://app.ggc.nyc/game/coin?code=${code}`,
  "/throughtheconcretejungle": "https://storage.googleapis.com/ggc_cdn/ggc_zork/GGC_Teaser.zip",
  "/ggc": "https://info.ggc.nyc/",
  "/new-link": "https://info.ggc.nyc/",
  "/loom": "https://gitlab.com/",
  "/mullerband": "https://open.spotify.com/playlist/5rSueXdt8Or4kUDFcfpXrL?si=05abb45509d34b9a",
}

export default routes;
